'use strict';

const SELECTORS = {
	element: '.js-products-slider-no-products',
	parent: '.experience-Section_slider'
};

/**
 * Class to remove section from product slider without products.
 */
class RemoveSection {
	constructor() {
		this.editorial = $(SELECTORS.element);
		this.editorialParent = $(SELECTORS.element).closest(SELECTORS.parent);
		this.init();
	}

	init() {
		if (this.editorial.length > 0) {
			this.editorial.each((index, element) => {
				if (this.editorialParent) {
					this.editorialParent.remove();
				}
			});
		}
	}
}

module.exports = () => {
	new RemoveSection();
};
